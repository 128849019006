.creditCard {
    background-image: linear-gradient(91.2deg, rgba(136, 80, 226, 1) 4%, rgba(16, 13, 91, 1) 96.5%);
    /* Replace with path to your background image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 200px;
    /* Adjust as needed */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.gradient {
    /* Optional: Add a gradient overlay on top of the background image */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
}

.cardDetails {
    color: white;
    text-align: center;
}